const navbarToggler = document.getElementById('checkbox-menu');
const mobileMenu = document.getElementById('mobile-menu');
const mobileToggler = document.getElementById('mobile-toggler');
const submitCV = document.getElementById('work-form');
const submitButtonCV = document.getElementById('work-button');
const submitFile = document.getElementById('input-cv');
navbarToggler.addEventListener('change', () => {
  toggleNavbarState(mobileMenu);
});
mobileToggler.addEventListener('click', () => {
  toggleNavbarState(mobileMenu);
  navbarToggler.checked = false;
});

const toggleNavbarState = target => {
  target.classList.toggle('mobile-opened');
};

submitCV && submitCV.addEventListener('submit', evt => {
  const fileExtension = submitFile.value.split('.').pop();

  if (submitFile.value === '' || fileExtension !== 'pdf') {
    evt.preventDefault();
    submitFile.value = '';
    alert('Por favor, anexe um PDF válido.');
    return;
  } // submitCV.submit();

});
new Swiper('.swiper-container', {
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  // autoplay: {
  // 	delay: 8000,
  // },
  keyboard: {
    enabled: true
  },
  autoHeight: true,
  loop: true
});
const plansSwiper = new Swiper('.plans-box', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  breakpoints: {
    380: {
      slidesPerView: 1,
      spaceBetween: 20,
      autoplay: true
    },
    540: {
      slidesPerView: 2,
      spaceBetween: 30,
      autoplay: true
    },
    980: {
      slidesPerView: 3,
      spaceBetween: 40,
      autoplay: false
    }
  }
});
new Swiper(".comments-swiper", {
  slidesPerView: 1,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
});
window.addEventListener('load', function () {
  const root = plansSwiper?.$el[0] || undefined;
  const swiperWrapper = root.querySelector('.swiper-wrapper');
  const cards = root.querySelectorAll('.plans-card');
  console.log('root', root);
  console.log('plansSwiper', plansSwiper.$el[0]);
  const mobile = window.matchMedia('(max-width: 430px)').matches;
  console.log('mobile', mobile);
  const tablet = window.matchMedia('(min-width: 431) and (max-width: 768)').matches; // const desktop = window.matchMedia('(min-width: 541px) and (max-width: 980px)').matches;
  // const desktopXl = window.matchMedia('(min-width: 981px)').matches;

  if (mobile || tablet) {
    root.classList.remove('swiper-initialized', 'swiper-horizontal');
    swiperWrapper.classList.remove('swiper-wrapper');
    cards?.forEach(card => {
      card.classList.remove('swiper-slide');
    });
    plansSwiper.destroy(true, true);
  } else {
    new Swiper('.plans-box', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      breakpoints: {
        380: {
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: true
        },
        540: {
          slidesPerView: 2,
          spaceBetween: 30,
          autoplay: true
        },
        980: {
          slidesPerView: 3,
          spaceBetween: 40,
          autoplay: false
        }
      }
    });
  }
});
window.addEventListener('resize', function () {
  const root = plansSwiper?.$el?.[0] || document.querySelector('.plans-box .swiper-wrapper');
  console.log('root', root);
  const swiperWrapper = root.querySelector('.swiper-wrapper');
  const cards = root.querySelectorAll('.plans-card');
  const mobile = window.matchMedia('(max-width: 430)').matches;
  const tablet = window.matchMedia('(min-width: 431) and (max-width: 768)').matches;

  if (mobile || tablet) {
    root.classList.remove('swiper-initialized', 'swiper-horizontal');
    swiperWrapper.classList.remove('swiper-wrapper');
    cards?.forEach(card => {
      card.classList.remove('swiper-slide');
    });
    plansSwiper.destroy(true, true);
  } else {
    new Swiper('.plans-box', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      breakpoints: {
        380: {
          slidesPerView: 1,
          spaceBetween: 20,
          autoplay: true
        },
        540: {
          slidesPerView: 2,
          spaceBetween: 30,
          autoplay: true
        },
        980: {
          slidesPerView: 3,
          spaceBetween: 40,
          autoplay: false
        }
      }
    });
  }
});